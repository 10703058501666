@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Sen', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, #ff5f6d, #ffc371, #48c6ef, #6f86d6);
  background-size: 800% 800%;
  animation: Gradient 15s ease infinite;
}

@keyframes Gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rotate {
  transition: transform 0.3s;
}

.rotate:hover {
  transform: rotate(360deg);
}
